import React, { Fragment,useEffect } from 'react';
import Home from './View/Home';
import SearchFlightResult from './View/SearchFlightResults';
import About from './View/About';
import Contact from './View/Contact';
import Careers from './View/Careers';
import Customersupport from './View/CustomerSupport';
import GetPNRItinerary from './View/GetPNRItinerary';
import Flights from './View/Flights';
import Refundpolicy from './View/Refundpolicy';
import Termsconditions from './View/Termsconditions';
import Termsofservice from './View/Termsofservice';
import FlightTicketBooking from './View/Flightbooking';
import BookingPayment from './View/BookingPayment';
import SignUp from './Components/Commom/Signup';
import FMBanks from './View/FMBanks';
import FAQs from './Components/Commom/FAQs';
import BookingDetails from './Components/BookingPayment/Comman/BookingDetails';
import { Routes, Route } from 'react-router-dom';
import PopularAirLines from './Components/SEOPages/PopularAirLines';
import FlightsSearchEngine from './Components/SEOPages/FlightsSearchEngine';
import DomesticFlightsEngine from './Components/SEOPages/DomesticFlightsEngine';
import WriteToUs from './Components/Commom/WriteToUs';
// import UserPanel from './Components/BackendDesign/UserPanel/UserPanel';

// import Orders from './Components/BackendDesign/UserPanel/pages/Orders';

import MyUserPanel from './Components/BackendDesign/MyPanel/MyUserPanel';
import UserCompleteDetail from './Components/BackendDesign/MyPanel/Pages/common/UserCompleteDetail';
import BlogCollection from './View/BlogCollection';
import BlogContent from './Components/BlogsContent/BlogContent';
import AddBlog from './Components/BackendDesign/AdminPanel/Blogs/AddBlog';
import BlogbyCategory from './Components/BlogsContent/BlogbyCategory';

import IntFlights from './Components/SEOPages/IntAndDomFlights';
import SubPopularAirline from './Components/SEOPages/SubPopularAirline';
import WebsiteUnderConstruction from './View/WebsiteUnderConstruction';
import ArrangeCallPage from './Components/Commom/ArrangeCallPage';
import VisasDetail from './Components/Visas/VisasDetail';
import VisaCategory from '../src/Components/Visas/VisaCategory';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import RetrivePNRDetails from './Components/RetrivePNR/RetrivePNRDetails';

import LahoreToAbuDhabi from './Components/Commom/LahoreToAbuDhabi';
const AppRouter = () => {

  const checkAccessToken = Cookies.get('Access_token') ? true:false;

  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route exact  path="/visa" element={<VisasDetail/>} />
        <Route exact  path="/retrivepnr" element={<RetrivePNRDetails/>} />
        {/* <Route exact  path="/visa/:visaType" element={<VisaCategory/>} /> */}
        <Route path="/searchflightresult/" element={<SearchFlightResult />} />
        <Route exact path="/flights/:from" element={<IntFlights/>} />
        {/* <Route path="/:from" element={<IntFlights/>} /> */}
        <Route path="/flightbooking" element={<FlightTicketBooking />} />
        <Route path ="/bookingDetail" element = {<BookingDetails/>}/>
        <Route path="/about-us" element={<About />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/RequestCall" element={<ArrangeCallPage />} />
        <Route path="/job-careers" element={<Careers />} />
        <Route path="/arrangeCall" element={<ArrangeCallPage />} />
        <Route path="/customer-support" element={<Customersupport />} />
        <Route path="/customer-support/:title" element={<Customersupport />} />
        <Route path="/flights" element={<Flights />} />
        <Route path="/refund-policy" element={<Refundpolicy />} />
        <Route path="/term-and-condition" element={<Termsconditions />} />
        <Route path="/terms-of-service" element={<Termsofservice />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/profile" element={<SignUp />} />
        <Route path="/bookingpayment" element={<BookingPayment />} />
        <Route path="/previewEticket" element={<GetPNRItinerary />} />
        <Route path="/banks" element={<FMBanks />} />
        <Route path="/FAQs" element={<FAQs/>} />
        <Route path="/updateblog/:id" element={<AddBlog/>} />
        <Route path="/blogs" element={<BlogCollection/>} />
        <Route path="blogs/:headerUrl" element={<BlogContent />} />
        <Route path="/userPanel" element={<AdminElement><MyUserPanel/></AdminElement>} />
        <Route path="/userDetails" element={<UserCompleteDetail/>} />
       
        <Route path="/category/:modifiedCategoryName" element={<BlogbyCategory/>} />
       <Route  path = '/UnderConstruction'  element = {<WebsiteUnderConstruction/>}/>
       <Route path="/:airlineName/:cityName" element={<SubPopularAirline/>} />
       <Route path="/:airlineName" element={<PopularAirLines/>} />
       
       <Route path = "*" element= {<div>{<WebsiteUnderConstruction/>}</div>}/>
      
        {/* <Route path="/:modifiedCategoryName/:headerUrl" element={<BlogContent/>} /> */}
        <Route path="/flights/lahore-to-abuDhabi" element={<LahoreToAbuDhabi/>} />


      </Routes>
    </Fragment>
  );
  function AdminElement({children}) {
    const navigate = useNavigate();
    useEffect(() => {
      if (!checkAccessToken) {
        navigate('/signup');
        console.log("User is Login ")
      }
    }, [checkAccessToken]);
    if(checkAccessToken){
      console.log("User is Login ")
      return <>
         {children}
      </>
    }else{
      navigate('/signup');
    }
  }
};

export default AppRouter;
